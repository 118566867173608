import React from 'react';

import Tophead from './Tophead';
import Mainpage from './Mainpage';

function App() {
  return (
    <>
     <Tophead/>
    <Mainpage/>
    </>
   
  );
}

export default App;
