import React from 'react';
import logoimage from './images/logo2.png';

export default function Mainpage() {
  return (
    <>
   
    <div className="flex justify-center  ">
      <img src={logoimage} alt="logo"  className=" h-50 mt-20 " />
      
    </div>
    <div className='flex justify-center text-2xl gap-x-2'>

    <div className='mt-4'>
    <span className='text-cuscolor-light font-bold'>Online</span>
  </div>
  <div className='mt-4'>
    <span className='text-cuscolor font-bold'>पाठशाला</span>
  </div>
    </div>

    <div className="flex flex-col  mt-6">
  <div className="  flex justify-center ">
    <p className=" mx-[60px] text-justify text-xl text-gray-500 font-bold">
      On this platform, you will have the opportunity to learn various programming languages in an online setting. We also offer internships to help you enhance and apply your knowledge practically. Our platform is designed to bridge the gap between students’ skills and the competencies required by industries. Additionally, we provide a range of workshops on diverse technologies to further support your learning journey.
    </p>
  </div>
  <div className='flex justify-center mt-6'> 
    <p className='text-cuscolor-light text-3xl font-extrabold flex justify-center'>Website is in Development Phase</p>
  </div>
  
</div>

   
  </>
  );
}
